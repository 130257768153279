import { Component, Logger, $, store } from 'lib'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import Icon from 'Component/Asset/Icon/Icon'
import WatchOptionsStream from 'Component/Watch/Options/Stream/WatchOptionsStream'
import WatchOptionsLayout from 'Component/Watch/Options/Layout/WatchOptionsLayout'

const menuOptions = {
  MAIN: 'main',
  STREAM: 'stream',
  LAYOUT: 'layout'
}

const providerNames = {
  youtube: 'YouTube',
  twitch: 'Twitch',
  openrec: 'OPENREC',
  nimotv: 'NimoTV',
  mildom: 'Mildom',
  afreecatv: 'Soop',
  trovo: 'Trovo'
}

class WatchOptions extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.addListeners()
  }

  addListeners () {
    // using a store for this communication between WatchLive and WatchOptions
    // to avoid awkwardness when passing a prop solely for small
    store.onChange(
      'watch.options.smallActive',
      this.smallOptionsListener = (smallOptionsActive) => {
        smallOptionsActive && this.setState({ selectedMenu: menuOptions.MAIN })
      }
    )

    this.toggleMenu = (event) => {
      event.stopPropagation() // prevent the global `this.clickListener` from triggering
      this.setState({
        menuActive: !this.state.menuActive,
        selectedMenu: menuOptions.MAIN
      })
    }

    this.updateSelectedMenu = (event, menuOption) => {
      event.stopPropagation() // prevent the global `this.clickListener` from triggering
      this.setState({ selectedMenu: menuOption })
    }

    this.streamChangeHandler = (selectedStream) => {
      this.setState({ menuActive: false })
      this.props.streamChangeHandler
        && this.props.streamChangeHandler(selectedStream)
    }

    this.layoutChangeHandler = (selectedLayout) => {
      this.setState({ menuActive: false })
      this.props.layoutChangeHandler
        && this.props.layoutChangeHandler(selectedLayout)
    }

    $(document).on(
      'click',
      this.clickListener = (event) => {
        if (
          this.state.menuActive
          && !$(event.target).closest(this.base).length
        ) {
          this.setState({
            menuActive: false,
            selectedMenu: menuOptions.MAIN
          })
        }
      }
    )
  }

  componentWillMount () {
    this.setState({ selectedMenu: menuOptions.MAIN })
  }

  componentWillUnmount () {
    store.removeListener('watch.options.smallActive', this.smallOptionsListener)
    $(document).off('click', this.clickListener)
  }

  renderMainMenuOptionSection (title, selectedOption, menuOption) {
    const optionSectionClasses = util.classNames(
      'options-section',
      menuOption + '-section'
    )

    return (
      <div class={ optionSectionClasses }>
        <div class="title">{ title }</div>
        <div class="options-list">
          <div
            class="option"
            role="button"
            onclick={ (event) => this.updateSelectedMenu(event, menuOption) }>
            <div class="label">{ selectedOption }</div>
            <Icon name="arrow" direction="right"/>
          </div>
        </div>
      </div>
    )
  }

  renderStreamOptionsButton () {
    const currentStream = this.props.selectedStream
    const { language } = locale.getLanguageFromMediaLocale(
      currentStream.mediaLocale
    )

    const option = language + ', ' + providerNames[currentStream.provider]

    return this.renderMainMenuOptionSection(
      locale.translate('watch.streamOptions'),
      option,
      menuOptions.STREAM
    )
  }

  renderStatsLayoutButton () {
    return this.renderMainMenuOptionSection(
      locale.translate('watch.layoutSelection'),
      locale.translate('watch.layoutSelection.' + this.props.selectedLayout),
      menuOptions.LAYOUT
    )
  }

  renderStreamMenu () {
    return (
      <WatchOptionsStream
        selectedStream={ this.props.selectedStream }
        streams={ this.props.streams }
        streamChangeHandler={ this.streamChangeHandler }
      />
    )
  }

  renderLayoutMenu () {
    return (
      <WatchOptionsLayout
        selectedLayout={ this.props.selectedLayout }
        layoutChangeHandler={ this.layoutChangeHandler }
      />
    )
  }

  renderMainMenu () {
    return (
      <div class="main-menu">
        { this.renderStreamOptionsButton() }
        { this.props.layoutChangeHandler && this.renderStatsLayoutButton() }
      </div>
    )
  }

  renderWatchOptions () {
    switch (this.state.selectedMenu) {
      case menuOptions.STREAM:
        return this.renderStreamMenu()
      case menuOptions.LAYOUT:
        return this.renderLayoutMenu()
      case menuOptions.MAIN:
      default:
        return this.renderMainMenu()
    }
  }

  render () {
    const { streams, streamChangeHandler, selectedStream } = this.props

    if (!streams || !streamChangeHandler || !selectedStream) {
      return this.log.error(
        'Missing mandatory properties for',
        this.constructor.name
      )
    }

    const classes = util.classNames(
      this.constructor.name,
      this.state.menuActive && 'active'
    )

    return (
      <div class={ classes }>
        <div class="options-button" onclick={ this.toggleMenu }>
          { locale.translate('watch.vods.options') }
          <Icon name="arrow" direction="down"/>
        </div>
        <div class="watch-options">{ this.renderWatchOptions() }</div>
      </div>
    )
  }
}

export default WatchOptions
