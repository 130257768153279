import { Component, Logger } from 'lib'
import locale from 'Common/Locale/Locale'
import util from 'Common/Util/Util'
import watchUtil from 'Container/Watch/Util/WatchUtil'
import Icon from 'Component/Asset/Icon/Icon'

class WatchOptionsStream extends Component {
  constructor () {
    super()
    this.log = new Logger(this.constructor.name)
    this.addListeners()
  }

  componentWillMount () {
    const { streams, selectedStream } = this.props

    if (streams && selectedStream) {
      this.setState({
        selectedStream,
        selectedProvider: selectedStream.provider
      })
      this.buildProviderReferences()
    }
  }

  addListeners () {
    this.switchProviders = (event) => {
      const selectedProvider = event.currentTarget.getAttribute('data-provider')

      if (selectedProvider === this.state.selectedProvider) return

      const currentStreamLanguage = this.state.selectedStream.locale
      const providerStreams = this.state.providerMap[selectedProvider]

      const matchingLanguageStreams = providerStreams.filter(
        (stream) => stream.locale === currentStreamLanguage
      )

      if (matchingLanguageStreams.length === 0) {
        // TODO: inform user that there are no matching streams
        this.log.info('No streams with language matching previous stream.')
        this.setState({ selectedProvider })
      }
      else {
        if (matchingLanguageStreams.length > 1) {
          this.log.warn(
            'Multiple streams with same provider'
              + 'and language detected. Defaulting to first.'
          )
        }

        const selectedStream = matchingLanguageStreams[0]

        this.setState({
          selectedStream,
          selectedProvider
        })

        this.props.streamChangeHandler
          && this.props.streamChangeHandler(selectedStream)
      }
    }

    this.loadStream = (event) => {
      const streamId = event.currentTarget.getAttribute('data-stream-id')
      const currentStreamProvider = this.state.selectedProvider
      const providerStreams = this.state.providerMap[currentStreamProvider]

      const matchingStreams = providerStreams.filter(
        (stream) => stream.parameter === streamId
      )

      if (matchingStreams.length === 0) {
        return this.log.error(
          'No stream matching id of selected stream option.'
        )
      }

      if (matchingStreams.length > 1) {
        this.log.warn('Multiple streams with same id detected.')
      }

      const selectedStream = matchingStreams[0]

      this.setState({ selectedStream })
      this.props.streamChangeHandler
        && this.props.streamChangeHandler(selectedStream)
    }
  }

  buildProviderReferences () {
    const streams = util.isIE()
      ? watchUtil.removeProvidersWithoutIESupport(this.props.streams)
      : this.props.streams

    const providerMap = this.mapStreamsToProviders(streams)
    const providers = Object.keys(providerMap)

    this.setState({
      providerMap,
      providers
    })
  }

  // force provider references to be rebuilt when switching games as the locales
  // of the streams of one game are not guaranteed to be the same as the other
  componentDidUpdate (prevProps) {
    if (this.props !== prevProps) {
      this.buildProviderReferences()
    }
  }

  renderProviderList () {
    const providerList = []

    this.state.providers.map((provider) => {
      const isCurrentProvider = provider === this.state.selectedProvider
      const providerLabel = provider === 'afreecatv' ? 'soop' : provider

      const streamOptionClasses = util.classNames(
        'option',
        provider,
        isCurrentProvider && 'selected'
      )

      providerList.push(
        <li
          class={ streamOptionClasses }
          data-provider={ provider }
          onclick={ this.switchProviders }>
          <span class="label">{ providerLabel }</span>
          <Icon name={ provider }/>
        </li>
      )
    })

    return (
      <div class="options-section provider-selection">
        <div class="title">{ locale.translate('watch.providerSelection') }</div>
        <ul class="providers options-list">{ providerList }</ul>
      </div>
    )
  }

  renderStreamList () {
    const currentProvider = this.state.selectedProvider
    const currentStreamParameter = this.state.selectedStream.parameter
    const streamList = []

    this.state.providerMap[currentProvider].map((stream) => {
      const { language, code } = locale.getLanguageFromMediaLocale(
        stream.mediaLocale
      )
      const isCurrentStream = stream.parameter === currentStreamParameter

      const streamOptionClasses = util.classNames(
        'option',
        isCurrentStream && 'selected'
      )

      const streamOption = (
        <li
          role="button"
          class={ streamOptionClasses }
          data-stream-id={ stream.parameter }
          onclick={ this.loadStream }>
          <span class="label">{ language }</span>
          <span class="code">{ code }</span>
        </li>
      )

      // force selected option to top of list
      isCurrentStream
        ? streamList.unshift(streamOption)
        : streamList.push(streamOption)
    })

    return (
      <div class="options-section stream-selection">
        <div class="title">{ locale.translate('watch.languageSelection') }</div>
        <ul class="streams options-list">{ streamList }</ul>
      </div>
    )
  }

  mapStreamsToProviders (streams) {
    const providerMap = {}

    streams.map((stream) => {
      if (!providerMap[stream.provider]) {
        providerMap[stream.provider] = [stream]
      }
      else {
        providerMap[stream.provider].push(stream)
      }
    })

    return providerMap
  }

  render () {
    const { streams, streamChangeHandler, selectedStream } = this.props

    if (!streams || !streamChangeHandler || !selectedStream) {
      return this.log.error(
        'Missing mandatory properties for',
        this.constructor.name
      )
    }

    return (
      <div class={ this.constructor.name }>
        { this.renderProviderList() }
        { this.renderStreamList() }
      </div>
    )
  }
}

export default WatchOptionsStream
